import React, { useEffect, useState } from 'react';
import { Table, Modal, DatePicker, Input, Button, Tooltip, Space, Radio, message } from 'antd';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import InitialParams from '../../components/InitialParams';
import getMyDate from '../../components/getMyDate';
import macMaskInput from '../../components/macMaskInput';
import DeviceManagerDrawer from '../deviceManager/components/DeviceManagerDrawer';
import REQUESTS from '../../api/requests';
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../features/Translation/TranslationSlice';
import TEXT from '../../config/text';
import classes from './index.module.scss';
import axios from 'axios';
import { notification } from 'antd';
import pLimit from 'p-limit';
import { DollarOutlined, RedoOutlined, CalendarOutlined, SearchOutlined  } from '@ant-design/icons';


const { RangePicker } = DatePicker;
const { confirm } = Modal;


function PaymentHistory() {
    const [open, setOpen] = useState(false);
    const [current, setCurrent] = useState(null);
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(100);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [date, setDate] = useState("");
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({ comment: "" });

    const [searchParams] = useSearchParams();
    const translation = useSelector(selectTranslation);
    const navigate = useNavigate();

    // InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <Input
                allowClear
                autoFocus
                placeholder="Type text here"
                value={selectedKeys[0]}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({ closeDropdown: false });
                }}
            />
        ),
        filterIcon: () => <SearchOutlined />,
    });
    const getLast12Digits = (id) => id.toLowerCase().slice(-12);


    const columns = [
        {
            title: "#",
            dataIndex: "id",
            width: "60px",
            align: "center",
            render: (text, record, index) => (
                currentPage === 1 ? index + 1 : limit * (currentPage - 1) + index + 1
            ),
        },
        {
            title: "MAC",
            dataIndex: ["metadata", "mac_address"],  // Access MAC from metadata
            key: "mac_address",
            align: "center",
            render: (mac_address) => mac_address || "N/A",
            ...macMaskInput(),
        },
        {
            title: "Reference",
            dataIndex: "reference",
            fixed: "left",
            align: "center",
            render: (reference) => reference || "N/A", // Display method or 'N/A' if not provided
            ...getColumnSearchProps(),
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            align: "center",
            render: (amount, record) => `${(amount / 100).toFixed(2)} ${record.currency || 'USD'}`, // Convert cents to USD and format to 2 decimal places
        },
        {
            title: "Date",
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            render: (createdAt) => new Date(createdAt).toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false, // Ensures 24-hour format
            }),
            filterDropdown: () => (
                <div style={{ padding: 8 }}>
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined style={{ color: JSON.parse(searchParams.get("between"))?.createdAt ? "#1890ff" : undefined }} />
            ),

            sorter: true, // Enable sorting
        },
        {
            title: "Response",
            dataIndex: "gateway_response",
            key: "gateway_response",
            align: "center",
            render: (gateway_response) => (
                <div style={{ 
                    textAlign: 'left' // Centers text within the div
                }}>
                    {gateway_response || "N/A"}
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: ["metadata", "email2"],  // Access email from metadata
            key: "email2",
            align: "center",
            render: (email2) => (
                <div style={{ 
                    textAlign: 'left' // Centers text within the div
                }}>
                    {email2 || "N/A"}
                </div>
            ),
        },
        {
            title: "IP Address",
            dataIndex: "ip_address",
            key: "ip_address",
            align: "center", // This centers the content horizontally in the table cell
            render: (ip_address) => (
                <div style={{ 
                    maxWidth: '170px', 
                    whiteSpace: 'nowrap', 
                    overflow: 'hidden', 
                    textOverflow: 'ellipsis',
                    textAlign: 'left' // Centers text within the div
                }}>
                    {ip_address || "N/A"}
                </div>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            align: "center",
            render: (status) => (
                <div style={{ textTransform: 'uppercase', textAlign: 'left' }}>
                    {status || "N/A"}
                </div>
            ),
            filters: [
                { text: "Success", value: "success" },
                { text: "Failed", value: "failed" },
                { text: "Abandoned", value: "abandoned" },
                { text: "REFUNDED", value: "REFUNDED" },
            ],
            filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm }) => (
                <>
                    <div style={{ padding: "6px 0 0 6px" }}>
                        <Radio.Group onChange={(e) => setSelectedKeys([e.target.value])} value={selectedKeys[0]}>
                            {filters.map(filteredItem => (
                                <div key={filteredItem.value}><Radio value={filteredItem.value}>{filteredItem.text}</Radio></div>
                            ))}
                        </Radio.Group>
                    </div>
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedKeys([]);
                                    confirm();
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>
                            <Button
                                size="small"
                                style={{ backgroundColor: "#000", color: '#fff' }}
                                onClick={() => confirm()}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                </>
            ),
        },        
    ];

    const buildSearchQuery = () => {
        const searchQuery = {};
        Object.keys(search).forEach((key) => {
            if (search[key]) {
                searchQuery[key] = search[key][0];
            }
        });
        return searchQuery;
    };

    const getPaymentHistory = async () => {
        setLoading(true);
    

        
    
        const buildSearchQuery = () => {
            const searchQuery = {};
            Object.keys(search).forEach((key) => {
                if (search[key]) {
                    searchQuery[key] = search[key][0];
                }
            });
            return searchQuery;
        };

        const query = {
            page: currentPage,
            limit,
            sort: JSON.stringify(sort),
            search: JSON.stringify(buildSearchQuery()), // Ensure the search query is a JSON string
        };
        query.search = JSON.stringify(buildSearchQuery());


        if (date && date[0]) {
            let from = new Date(date[0]);
            let to = new Date(date[1]);
            from.setHours(0, 0, 0, 0);
            to.setHours(0, 0, 0, 0);
            to.setDate(to.getDate() + 1); // Add one day to include the whole end date
            query.between = JSON.stringify({
                createdAt: { from, to },
            });
        }


    
        try {
            const response = await axios.get('https://api.lahza.io/transaction', {
                params: query,
                headers: {
                    authorization: `Bearer Xsk_live_9Yxctad9Fty2d6U6493dYZ4I0ue7D0rQw`,
                },
            });
    
            console.log('API Response:', response.data);
    
            const { data, meta } = response.data;
    
            setTotal(meta.total);
            setPaymentHistory(data);
        } catch (error) {
            console.error('Failed to fetch payment history:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to fetch payment history.',
                placement: 'topLeft',
            });
        } finally {
            setLoading(false);
        }
    };
            
    useEffect(() => {
        const timeout = setTimeout(() => {
            getPaymentHistory();
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [currentPage, limit, sort, search, date]);

    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
        setSearch(filters);
        setSort([sorter.field ? sorter.field : "id", sorter.order === "ascend" ? "ASC" : "DESC"]);
    };

    return (
        <div>
            <h1 className='table-title'>Lahza.io Transaction</h1>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={paymentHistory}
                onChange={handleTableChange}
                rowClassName={(record, index) => `${["row"]} cursor-pointer`}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,  // Use the total from the meta
                    pageSize: limit,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />
        </div>
    );
}

export default PaymentHistory;
