import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import {
  DashboardOutlined,
  DesktopOutlined,
  HistoryOutlined,
  LockOutlined,
  ShoppingOutlined,
  SettingOutlined,
  AliwangwangOutlined,
  MailOutlined,
  WechatOutlined,
  ContainerOutlined, GlobalOutlined,
  SettingFilled,
  DollarOutlined,
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import TEXT from "../../config/text";
import ICONS from "../../config/icons";

import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { selectMeesageCount } from "../../features/chat/chatSlise";
import { selectwidgets } from "../../features/widgets/selector";

import classes from "../style/sidbar.module.scss";
import "../../index.scss";

const VERSION = require('../../../package.json').version

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidbar({ collapsed, setOpen }) {
  const translation = useSelector(selectTranslation);
  const meesageCount = useSelector(selectMeesageCount);
  const widgets = useSelector(selectwidgets);

  const [apiVersion, setApiVersion] = useState("");

  const location = useLocation();
  const [current, setCurrent] = useState(location.pathname);

  const items = [
    getItem(
      <Link className={classes["menu_item_link"]} to={"./"}>
        {translation["Dashboard"] || TEXT["Dashboard"]}
      </Link>,
      "/DashboardPage",
      ICONS.HOME,
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./deviceManager"}>
        {translation["Devices"] || TEXT["Devices"]}
      </Link>,
      "/DashboardPage/deviceManager",
      ICONS.DEVICES,
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./paymentHistory"}>
        {translation["Payments"] || TEXT["Payments"]}
      </Link>,
      "/DashboardPage/paymentHistory",
      <DollarOutlined />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./Lahza"}>
        Lahza.io
      </Link>,
      "/DashboardPage/Lahza",
      ICONS.PAYMENT_DONE,
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"playlists"}>
        Playlists
      </Link>,
      "/DashboardPage/playlists",
      ICONS.USERS,
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./userPackages"}>
        {translation["Packages"] || TEXT["Packages"]}
      </Link>,
      "/DashboardPage/userPackages",
      ICONS.PACKAGE,
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./reseller"}>
          {translation["Resellers"] || TEXT["Resellers"]}
        </Link>
      </div>,
      "/DashboardPage/reseller",
      ICONS.RESELLER_ACTIVATION,
    ),
    getItem(
          <div className="label-icon-container">
          <Link className={classes["menu_item_link"]} to={"./referralactivation"}>
          {translation["Resellers history"] || TEXT["Resellers history"]}
          </Link>
          </div>,
      "/DashboardPage/referralactivation",
      ICONS.RESELLER_REGISTERED,
        ),
    getItem(
      <div className="label-icon-container">
      <Link className={classes["menu_item_link"]} to={"./referralpackages"}>
      {translation["Resellers Credit"] || TEXT["Resellers Credit"]}
      </Link>
      </div>,
  "/DashboardPage/referralpackages",
  ICONS.PLATFORMS,
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"./GIFTCode"}>
          {translation["Gift Codes"] || TEXT["Gift Codes"]}
        </Link>
      </div>,
      "/DashboardPage/coupons",
      ICONS.GIFT,
    ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"notification"}>
          {translation["Notification"] || TEXT["Notification"]}
        </Link>
      </div>,
      "/DashboardPage/notification",
      <span>
        {ICONS.NOTIFICATION}
      </span>
    ),
    widgets?.free_dns?.enabled === "true" &&
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"free-dns"}>
          {translation["Free DNS"] || TEXT["Free DNS"]}
        </Link>
      </div>,
      "/DashboardPage/free-dns",
      ICONS.WORLD_DNS
        ),
    getItem(
      <div className="label-icon-container">
        <Link className={classes["menu_item_link"]} to={"manage-categories"}>
          {translation["Manage Categories"] || TEXT["Manage Categories"]}
        </Link>
      </div>,
      "/DashboardPage/manage-categories",
      ICONS.TV_CATEGORIES
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./settings"}>
        {translation["Settings"] || TEXT["Settings"]}
      </Link>,
      "/DashboardPage/settings",
      <SettingFilled />
    ),
    getItem(
      <Link className={classes["menu_item_link"]} to={"./blacklist"}>
        {translation["Blacklist"] || TEXT["Blacklist"]}
      </Link>,
      "/DashboardPage/blacklist",
      <LockOutlined />
    ),
  ];


  const getApiVersion = () => {
    REQUESTS.GET_API_VERSION((data) => {
      setApiVersion(data)
    });
  };

  useEffect(() => {
    getApiVersion();
  }, []);
  return (
    <div className={classes["sidebar"]}>
      <Menu
        selectedKeys={[current]}
        mode="inline"
        inlineCollapsed={collapsed}
        items={items}
        style={{
          paddingTop: 0,
          fontSize: 15,
          minHeight: "100%",
          backgroundColor: "transparent",
        }}
        onClick={(e) => {
          setCurrent(e.key);
          if (setOpen) {
            setOpen(false);
          }
        }}
      />
      <div
        className={classes["sidebar-footer-section"]}
        style={{
          fontSize: 10,
          marginLeft: 10,
          position: 'fixed',
          bottom: 0,
          }}
      >
        <span>API {apiVersion}</span>
        <p>Admin v{VERSION}</p>
      </div>
    </div>
  );
}

export default Sidbar;
